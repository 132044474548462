<template>
  <div class="mian user">
    <div class="user_top">
      <div class="user_info clearfix">
        <div class="fl">
          <div
            class="user_info_user clearfix"
            :class="is_benren == 1 ? '' : 'user_info_users'"
          >
            <img class="fl" :src="userInfo.thumb" alt="" />
            <div class="fl user_info_user_box">
              <div class="user_info_user_name">
                <span>{{ userInfo.username }}</span>
                <template v-if="userInfo.is_lifelong_vip == 0">
                  <router-link to="../vip">
                    <img
                      v-if="userInfo.video_vip == 1"
                      src="../../assets/images/viplogo/type_one_v.svg"
                      alt=""
                      title="视频会员"
                    />
                    <img
                      v-else
                      src="../../assets/images/spvipno.svg"
                      alt=""
                      title="视频会员"
                    />
                  </router-link>
                  <router-link to="../vip">
                    <img
                      v-if="userInfo.down_vip == 1"
                      src="../../assets/images/viplogo/type_one_s.svg"
                      alt=""
                      title="视频会员"
                    />
                    <img
                      v-else
                      src="../../assets/images/scvipno.svg"
                      alt=""
                      title="素材会员"
                    />
                  </router-link>
                </template>
                <router-link to="../vip" v-else>
                  <img
                    src="../../assets/images/vsvip2.svg"
                    title="终身会员"
                    alt=""
                  />
                </router-link>
                <div>用户ID：{{ userInfo.id }}</div>
              </div>
              <div class="user_info_user_signature">
                {{ userInfo.describe || "这个人很懒，什么也没有留下..." }}
              </div>
              <div class="user_info_time" v-if="is_benren == 1">
                <div
                  :class="
                    userInfo.video_vip == 1
                      ? 'vip_one'
                      : 'vip_one vip_one_colour'
                  "
                >
                  <div class="vip_one_head">视频会员</div>
                  <template v-if="userInfo.is_lifelong_vip == 0">
                    <div class="vip_one_text" v-if="userInfo.video_vip == 1">
                      <span
                        >{{
                          userInfo.video_end_time.substring(0, 10)
                        }}到期</span
                      ><a @click="toBuy">续费</a>
                    </div>
                    <div class="vip_one_text2" v-else>
                      <a @click="toBuy">立即充值</a>
                    </div>
                  </template>
                  <div class="vip_one_text" v-else>
                    <span>终身畅享海量视频教程</span>
                  </div>
                </div>
                <div
                  :class="
                    userInfo.down_vip == 1
                      ? 'vip_one'
                      : 'vip_one vip_one_colour'
                  "
                >
                  <div class="vip_one_head">素材会员</div>
                  <template v-if="userInfo.is_lifelong_vip == 0">
                    <div class="vip_one_text" v-if="userInfo.down_vip == 1">
                      <span
                        >{{ userInfo.down_end_time.substring(0, 10) }}到期</span
                      ><a @click="toBuy">续费</a>
                    </div>
                    <div class="vip_one_text2" v-else>
                      <a @click="toBuy">立即充值</a>
                    </div>
                  </template>
                  <div class="vip_one_text" v-else>
                    <span>海量素材终身免费下载</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fr user_info_r clearfix">
          <div class="fl user_info_r_item" @click="toIntegral">
            <div>{{ userInfo.integral }}</div>
            <span>积分</span>
          </div>
          <div class="fl user_info_r_item">
            <div>{{ userInfo.mood }}</div>
            <span>人气</span>
          </div>
          <div
            class="fl user_info_r_item"
            @click="toTabOtherFun(1, 'userDaily')"
          >
            <div>{{ userInfo.task_num }}</div>
            <span>作品</span>
          </div>
          <div
            class="fl user_info_r_item"
            @click="toTabOtherFun(2, 'userDaily')"
          >
            <div>{{ userInfo.task_list_num }}</div>
            <span>上榜</span>
          </div>
          <div class="fl user_info_r_item" @click="toTabFun('userFans')">
            <div>{{ userInfo.fans_num }}</div>
            <span>粉丝</span>
          </div>
          <div class="fl user_info_r_item" @click="toTabFun('userFocus')">
            <div>{{ userInfo.follow_num }}</div>
            <span>关注</span>
          </div>
        </div>
      </div>

      <div class="user_tab">
        <div
          v-if="tab == 1"
          :class="tabIndex == 1 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userDaily')"
        >
          <img
            v-if="tabIndex == 1"
            src="../../assets/images/icon_zhuye_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_zhuye_02@2x.svg"
            alt=""
            class="height"
          />
          {{ userInfo.is_benren == 1 ? "个人主页" : "TA的主页" }}
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 1"
          :class="tabIndex == 2 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userFocus')"
        >
          <img
            v-if="tabIndex == 2"
            src="../../assets/images/icon_guanzhu_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_guanzhu_02@2x.svg"
            alt=""
            class="height"
          />
          {{ userInfo.is_benren == 1 ? "我的关注" : "TA的关注" }}
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 1"
          :class="tabIndex == 3 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userFans')"
        >
          <img
            v-if="tabIndex == 3"
            src="../../assets/images/icon_fans_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_fans_02@2x.svg"
            alt=""
            class="height"
          />
          {{ userInfo.is_benren == 1 ? "我的粉丝" : "TA的粉丝" }}
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2"
          :class="tabIndex == 1 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userDaily')"
        >
          <img
            v-if="tabIndex == 1"
            src="../../assets/images/icon_fabu_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_fabu_02@2x.svg"
            alt=""
            class="height"
          />
          每日一字
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2"
          :class="tabIndex == 2 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userCollect')"
        >
          <img
            v-if="tabIndex == 2"
            src="../../assets/images/icon_collection_1@2x.svg"
            class="height"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/icon_collection_0@2x.svg"
            class="height"
            alt=""
          />
          {{ userInfo.is_benren == 1 ? "我的收藏" : "TA的收藏" }}
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2 && is_benren == 1"
          :class="tabIndex == 3 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userDownload')"
        >
          <img
            v-if="tabIndex == 3"
            src="../../assets/images/icon_download_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_download_02@2x.svg"
            alt=""
            class="height"
          />
          我的下载
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2"
          :class="tabIndex == 4 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userLearn')"
        >
          <img
            v-if="tabIndex == 4"
            src="../../assets/images/icon_xuexi_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_xuexi_02@2x.svg"
            alt=""
            class="height"
          />
          学习记录
          <span><i></i></span>
        </div>

        <div
          v-if="tab == 2 && is_benren == 1"
          :class="tabIndex == 6 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userOrder')"
        >
          <img
            v-if="tabIndex == 6"
            src="../../assets/images/icon_orderb.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_order.svg"
            alt=""
            class="height"
          />
          我的订单
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2"
          :class="tabIndex == 7 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userWork')"
        >
          <img
            v-if="tabIndex == 7"
            src="../../assets/images/workb.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/work.svg"
            alt=""
            class="height"
          />
          {{ userInfo.is_benren == 1 ? "我的作业" : "TA的作业" }}
          <span><i></i></span>
        </div>
        <div
          v-if="tab == 2 && is_benren == 1"
          :class="tabIndex == 8 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userCoupon')"
        >
          <img
            v-if="tabIndex == 8"
            src="../../assets/images/couponb.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/coupon.svg"
            alt=""
            class="height"
          />
          优惠券/码
          <span><i></i></span>
        </div>
       <div
         v-if="tab == 2 && is_benren == 1"
         :class="tabIndex == 9 ? 'active' : ''"
         class="user_tab_item"
         @click="toTabFun('userInvite')"
       >
         <img
           v-if="tabIndex == 9"
           src="../../assets/images/invites.svg"
           alt=""
           class="height"
         />
         <img
           v-else
           src="../../assets/images/invite.svg"
           alt=""
           class="height"
         />
         邀请页面
         <span><i></i></span>
       </div>
        <div
          v-if="tab == 2"
          :class="tabIndex == 5 ? 'active' : ''"
          class="user_tab_item"
          @click="toTabFun('userInfo')"
        >
          <img
            v-if="tabIndex == 5"
            src="../../assets/images/icon_ziliao_01@2x.svg"
            alt=""
            class="height"
          />
          <img
            v-else
            src="../../assets/images/icon_ziliao_02@2x.svg"
            alt=""
            class="height"
          />
          账号信息
          <span><i></i></span>
        </div>
      </div>
    </div>
    <div class="mian mians">
      <router-view ref="nextPage"></router-view>
    </div>
    <!-- 侧边导航 -->
    <sidebar ref="downtotal"></sidebar>
  </div>
</template>

<script>
import sidebar from "../../components/sidebar.vue";
export default {
  name: "user",
  components: {
    sidebar,
  },
  data() {
    return {
      tab: 2,
      tabIndex: 1,
      userInfo: {},
      is_benren: 1,
      userId: 0,
    };
  },
  mounted() {
    this.$parent.routerIndex = 0;
    this.$parent.showIndex = 1;
    //this.is_benren = localStorage.getItem('is_benren');
    this.userId = this.$route.query.userId;
    this.getUser();
  },
  methods: {
    //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,
        {
          user_id: this.userId,
        },
        function (res) {
          that.userInfo = res.data;
          that.is_benren = res.data.is_benren;
          // localStorage.setItem('is_benren', res.data.is_benren);
          // if (res.data.is_benren != 1) {
          // 	localStorage.setItem('userInfo_other', JSON.stringify(res.data))
          // }
        }
      );
    },
    toIntegral() {
      let routeData = this.$router.resolve({
        name: "integral",
      });
      window.open(routeData.href, "_blank");
    },

    toTabFun(url) {
      this.$router.push({
        name: url,
        query: {
          userId: this.userId,
        },
      });
    },

    // 跳转
    toTabOtherFun(e, url) {
      this.$router.push({
        name: url,
        query: {
          userId: this.userId,
        },
      });
      this.$nextTick(() => {
        this.$refs.nextPage.tabFun(e);
      });
    },

    toBuy() {
      this.$router.push({
        name: "vip",
      });
    },

    //头像添加地址
    thumbFun() {
      if (this.userInfo.thumb.indexOf("http") == -1) {
        this.userInfo.thumb = require("../../assets/images/head.png");
      }
    },
  },
  watch: {
    "userInfo.thumb"() {
      this.thumbFun();
    },
    "$route.query.userId": function () {
      localStorage.setItem("userId", this.$route.query.userId);
      this.getUser();
      if (this.$refs.nextPage.listFun) {
        this.$refs.nextPage.listFun();
      }
      if (this.$refs.nextPage.initFun) {
        this.$refs.nextPage.initFun();
      }
      console.log(this.$route.query.userId);
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.routerIndex = 0;
      this.$parent.showIndex = 1;
      //this.is_benren = localStorage.getItem('is_benren');
      //this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    });
  },
};
</script>

<style scoped>
.user {
  padding-top: 42px;
}

.user_top {
  width: 1400px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 10px;
}

.user_info {
  width: 100%;
  height: 224px;
  background: url(../../assets/images/userimg1.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  padding: 40px 75px 0 80px;
  box-sizing: border-box;
}

.user_info_user {
  margin-bottom: 7px;
}
.user_info_users {
  margin-top: 7px;
}

.user_info_user > img {
  width: 130px;
  height: 130px;
  border-radius: 70px;
  border: solid 2px #ffffff;
  box-sizing: border-box;
  margin-right: 18px;
}

.user_info_user_name {
  padding-top: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 8px;
}

.user_info_user_name span {
  margin-right: 7px;
}

.user_info_user_name div {
  display: inline-block;
  font-size: 12px;
  color: #f8ffff;
  margin-left: 9px;
}

.user_info_user_name img {
  width: 25px;
  height: 25px;
}

.user_info_user_name img:nth-child(3) {
  margin-left: 5px;
}

.user_info_user_signature {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #ffffff;
}

.user_info_time {
  position: relative;

  box-sizing: border-box;
}

.user_info_time p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.user_info_time img {
  width: 17px;
  height: 15px;
  position: absolute;
  top: 5px;
  left: 8px;
}

.user_info_tobuy {
  width: 56px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 13px;
  font-size: 12px;
  color: #ff882e;
  text-align: center;
  position: absolute;
  top: 0;
  right: -14px;
  cursor: pointer;
}

.user_info_r {
  padding-top: 47px;
}

.user_info_r_item {
  min-width: 108px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.user_info_r_item div {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 18px;
}

.user_info_r_item span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #ffffff;
}

.user_tab {
  height: 76px;
  display: flex;
  align-items: center;
}

.user_tab_item {
  width: 10.6%;
  height: 76px;
  /* margin-left: 62px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  color: #999999;
  position: relative;
  cursor: pointer;
}

.user_tab_item:hover {
  color: #5957ff;
}

.user_tab_item img.height {
  margin-right: 7px;
  height: 22px;
}
.user_tab_item img {
  margin-right: 7px;
}

.user_tab_item span {
  display: none;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 66%;
  bottom: 0;
  /* margin-left: 24px; */
  background-image: url(../../assets/images/navbg.png);
  background-repeat: no-repeat;
  background-size: 35px 35px;
  background-position: 0px 9px;
  padding-top: 29px;
  box-sizing: border-box;
}

.user_tab_item span i {
  display: block;
  width: 20px;
  height: 6px;
  background-color: #5957ff;
  margin: auto;
}

.user_tab_item.active {
  color: #5957ff;
}

.user_tab_item.active span {
  display: block;
}

.user_info_time {
  margin-top: 17px;
}

.vip_one {
  padding: 0 11px;
  width: 138px;
  height: 63px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.vip_one:nth-child(1) {
  background: url(../../assets/images/spvip_bg@3x.png) no-repeat;
  background-size: 100% 100%;
  color: #6762fa;
}

.vip_one:nth-child(2) {
  background: url(../../assets/images/scvip_vip@3x.png) no-repeat;

  background-size: 100% 100%;
  color: #9c522d;
}

.vip_one:nth-child(1) .vip_one_head {
  color: #6762fa;
}

.vip_one:nth-child(2) .vip_one_head {
  color: #9c522d;
}

.vip_one:nth-child(1) .vip_one_text a {
  color: #6762fa;
}

.vip_one:nth-child(2) .vip_one_text a {
  color: #9c522d;
}

.vip_one_colour {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.vip_one_colour {
  color: #979797;
}

.vip_one .vip_one_head {
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 3px;
  font-weight: 500;
}

.vip_one .vip_one_text {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  padding-top: 3px;
}

.vip_one .vip_one_text span {
  float: left;
}

.vip_one .vip_one_text a {
  width: 41px;
  height: 21px;
  display: block;
  float: right;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.vip_one .vip_one_text2 a {
  padding: 0 15px;
  height: 21px;
  display: inline-block;
  line-height: 21px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 4px;
}

.user_info_user_name .svgsize {
  width: 26px;
  height: 24px;
}
</style>
